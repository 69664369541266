import styles from './sms.module.css';
import { GridColumn, GridRow } from 'app/layouts/grid';
import React, { useEffect, useState } from 'react';
import { DetailItem } from 'app/shared/info-section/item';
import { IconInput } from 'app/shared/input';
import { useSelector } from 'react-redux';
import { CodeWrapper } from 'app/shared/wrappers/code';
import { Button } from 'app/shared/button';
import { useMessagingPreferenceService } from 'hooks/messaging/preference';

export const SMSConfiguration = () => {
  const { tenant_id } = useSelector((store) => store.user_data);
  const { fetchPreferences, setPreference } = useMessagingPreferenceService();

  const [api_key, setAPIKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [show_key, setShowkey] = useState(false);

  useEffect(() => {
    fetchPreferences().then(({ preferences: [preference] }) => {
      if (!preference) return;

      const { sms } = preference;
      setAPIKey(sms?.api_key || '');
    });
  }, []);

  const submit = async () => {
    if (!api_key) return;
    const data = {
      sms: { api_key },
      tenant_id
    };
    setLoading(true);
    await setPreference({ data });
    setLoading(false);
  };

  const toggleShow = () => setShowkey((show) => !show);

  return (
    <div className={styles.wrapper}>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Analytics webhook">
            <CodeWrapper text={`https://messaging.go-mailer.com/webhooks/sms/${tenant_id}`} />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="API Key">
            <IconInput
              icon_name={show_key ? 'hide' : 'show'}
              icon_position="right"
              onIconClick={toggleShow}
              type={show_key ? 'text' : 'password'}
              value={api_key}
              onInput={setAPIKey}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={2}>
        <GridColumn />
        <GridColumn>
          <Button loading={loading} disabled={loading || !api_key} text="Save" onClick={submit} />
        </GridColumn>
      </GridRow>
    </div>
  );
};
