import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as UserService from 'services/iam/user';

export const useUserService = () => {
  const { id: user_id, token } = useSelector((store) => store.user_data);

  const acceptInvitation = async (options = {}) => {
    const { error, payload } = await UserService.acceptInvitation({
      ...options,
      token
    });
    if (error) {
      toast.error('Unable to accept invitation at this time.');
      return null;
    }

    return payload;
  };

  const fetchOrganizations = async (options = {}) => {
    const { error, payload } = await UserService.readOrganizations(user_id, {
      ...options,
      token
    });
    if (error) {
      return { organizations: [], size: 0, error: 'Unable to fetch organizations at this time.' };
    }

    const {
      data: organizations,
      meta: { size }
    } = payload;
    return { organizations, size, error: null };
  };

  const fetchUserData = async (options = {}) => {
    const { error, payload } = await UserService.readById(user_id, { ...options, token });
    return { user: payload, error };
  };

  const updatePassword = async (id, options = {}) => {
    const { error, payload } = await UserService.updatePassword(id, {
      ...options,
      token
    });
    if (error) {
      return null;
    }

    return payload;
  };

  const updateUserData = async (id, options = {}) => {
    const { error, payload } = await UserService.updateById(id, {
      ...options,
      token
    });
    if (error) {
      toast.error('Unable to update user information at this time.');
      return null;
    }

    return payload;
  };

  return { acceptInvitation, fetchOrganizations, fetchUserData, updatePassword, updateUserData };
};
