import { Badge } from 'app/shared/badge';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { truncateText } from 'app/shared/utils/general';
import { Icon } from 'assets/icons';
import styles from './item.module.css';
import React, { useState } from 'react';
import GmModal from 'app/shared/modal/modal';
import { WebPushMessageForm } from '../../form';
import { processDuplicateName } from 'app/shared/utils/mailing';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ContextMenu from 'app/shared/datatable/context-menu/context-menu';
import { useWebPushService } from 'hooks/messaging/push/web';
import { addOnePushMessageToStore } from 'store/actions/push';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { toDateString } from 'app/shared/utils/date';
import { DetailItem } from 'app/shared/info-section/item';

export const WebPushListItem = ({ item_data = {} }) => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { createPushMessage, updatePushMessage } = useWebPushService();

  const [show_creation_modal, setShowCreationModal] = useState(false);

  const itemActions = (item) => [
    { label: 'Edit', value: 'edit' },
    { label: 'Clone', value: 'clone' },
    { label: 'Cancel', value: 'cancel', hidden: item.status !== 'queued' },
    { label: 'View', value: 'view' }
  ];

  const handleAction = (data) => {
    if (typeof data !== 'string') return;

    switch (data.toLowerCase()) {
      case 'edit':
        setShowCreationModal(true);
        break;
      case 'clone':
        handleClone();
        break;
      case 'cancel':
        handleCancel();
        break;
      default:
        navigateTo(`/messaging/push/web/${item_data.id}`);
    }
  };

  const handleCancel = async () => {
    if (!item_data.schedule || !item_data.schedule.date) return;

    const now = Date.now();
    const schedule_date = Date.parse(item_data.schedule.date);

    if (schedule_date < now || schedule_date - now < 5 * 60 * 1000) {
      toast.info('Cannot cancel message');
      return;
    }

    toast.info('Canceling Message...');
    const result = await updatePushMessage(item_data.id, { data: { status: 'draft' } });
    if (result) dispatch(addOnePushMessageToStore({ ...item_data, status: 'draft' }));
  };

  const handleClick = () => {
    navigateTo(`/messaging/push/web/${item_data.id}`);
  };

  const handleClone = async () => {
    const data = {
      audiences: item_data.audiences,
      message: item_data.message,
      schedule: item_data.schedule,
      sender_id: item_data.sender_id,
      sender_image: item_data.sender_image,
      status: 'draft',
      title: processDuplicateName(item_data.title, 'copy', '-'),
      type: item_data.type
    };

    toast.info('Cloning Message...');
    const { message } = await createPushMessage({ data });

    if (message) dispatch(addOnePushMessageToStore(message));
  };

  return (
    <>
      <BasicInfoSection
        bodyClassName={styles.contentWrapper}
        title={
          <span className={styles.title} onClick={handleClick}>
            {truncateText(item_data.title, 35)}
          </span>
        }
        icon={
          <span className={styles.icons}>
            <ContextMenu
              actions={itemActions(item_data)}
              callback={handleAction}
              text={<Icon name="more_vert" />}
            />
          </span>
        }
      >
        <GridRow num_of_columns={7}>
          <GridColumn span={3}>
            <DetailItem title="Body">
              <div>{truncateText(item_data.message, 75)}</div>
            </DetailItem>
          </GridColumn>
          <GridColumn>
            <DetailItem title="Sender ID">{item_data.sender_id}</DetailItem>
          </GridColumn>
          <GridColumn>
            <DetailItem title="Schedule">
              {item_data.type === 'campaign' ? toDateString(item_data.schedule.date) : 'n/a'}
            </DetailItem>
          </GridColumn>
          <GridColumn>
            <DetailItem title="Type">
              <Badge
                type={item_data.type === 'campaign' ? 'blue' : 'orange'}
                text={item_data.type}
              />
            </DetailItem>
          </GridColumn>
          <GridColumn>
            <DetailItem title="Status">
              <Badge
                type={
                  item_data.status === 'queued'
                    ? 'orange'
                    : item_data.status === 'processed'
                    ? 'green'
                    : 'red'
                }
                text={item_data.status}
              />
            </DetailItem>
          </GridColumn>
        </GridRow>
      </BasicInfoSection>
      <GmModal
        show_modal={show_creation_modal}
        show_title
        title="Edit message"
        onClose={() => setShowCreationModal(false)}
      >
        <WebPushMessageForm key={item_data.id} message_data={item_data} />
      </GmModal>
    </>
  );
};
