import { useSelector } from 'react-redux';
import * as ContactReportService from 'services/users/report';

export const useContactReportService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchReports = async (options = {}) => {
    const { error, payload } = await ContactReportService.read({
      ...options,
      token
    });
    if (error) {
      return { reports: [], error: 'Unable to fetch report at this time.' };
    }

    return { reports: payload.data, error: null };
  };

  const fetchComparativeReport = async (options = {}) => {
    const { error, payload } = await ContactReportService.readComparativeReport({
      ...options,
      token
    });
    if (error) {
      return { report: [], error: 'Unable to fetch comparative report at this time.' };
    }

    return { report: payload, error: null };
  };

  const fetchDistributionReports = async (options = {}) => {
    const { error, payload } = await ContactReportService.readDistributionReports({
      ...options,
      token
    });
    if (error) {
      return { distribution: [], error: 'Unable to fetch distribution report at this time.' };
    }

    return { distribution: payload, error: null };
  };

  const fetchPerformanceReport = async (options = {}) => {
    const { error, payload } = await ContactReportService.readPerformanceReport({
      ...options,
      token
    });
    if (error) {
      return { report: {}, error: 'Unable to fetch performance report at this time.' };
    }

    return { report: payload };
  };

  return {
    fetchComparativeReport,
    fetchDistributionReports,
    fetchPerformanceReport,
    fetchReports
  };
};
