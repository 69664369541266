import React from 'react';
import { AutomationEvents } from './list';
import { Route, Routes } from 'react-router-dom';
import { EventViewPage } from './view';
import { PageContentWrapper } from 'app/layouts/wrapper/page-content';

export const AppInsightModule = () => {
  return (
    <PageContentWrapper>
      <Routes>
        <Route path="/:event_code" element={<EventViewPage />} />
        <Route path="/*" element={<AutomationEvents />} />
      </Routes>
    </PageContentWrapper>
  );
};
