import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { WebsiteIntegration } from './items/website';
import useScreenSize from 'hooks/size';
import { useModuleService } from 'hooks/sales/module';
import { IntegrationItem } from './items';

const SubscriptionModule = (props) => {
  const dispatch = useDispatch();
  const { isTablet } = useScreenSize();
  const { fetchModules } = useModuleService();

  const [modules, setModules] = useState([]);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Subscriptions', path: '.' }]));
    fetchModules().then(({ modules: srv_modules }) => {
      setModules(srv_modules);
    });
  }, []);

  return (
    <PageContentWrapper>
      <GridRow num_of_columns={isTablet ? 2 : 4}>
        <GridColumn>
          <WebsiteIntegration />
        </GridColumn>
        {modules.map((module) => (
          <GridColumn key={module.code}>
            <IntegrationItem data={module} />
          </GridColumn>
        ))}
      </GridRow>
    </PageContentWrapper>
  );
};

export default SubscriptionModule;
