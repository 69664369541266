import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PrimaryStatCard } from 'app/shared/cards';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { useTransactionalService } from 'hooks/transactional';
import { useMailActionService } from 'hooks/mailaction';
import { convertToPercent, getMonthNameFromIndex } from 'app/shared/utils/general';
import { DistributionChart } from 'app/shared/charts/distribution';
import { TimeSeriesChart, processDateRange } from 'app/shared/charts/timeseries';
import { Spacer } from 'app/layouts/generic';
import { ReportTable } from 'app/shared/datatable/simple';
import { getMonthTimestamps } from 'app/shared/utils/date';
import { toReadableNumber } from 'app/shared/utils/number';
import useScreenSize from 'hooks/size';
import { SlidingStatBar } from 'app/layouts/stats/sliding';
import { toast } from 'react-toastify';
import { useMailingReportService } from 'hooks/mailing/report';
import { useContactReportService } from 'hooks/users/report';

export const TransactionalsDashboardSection = ({ date_range = [] }) => {
  const start = new Date(date_range[0]).getTime();
  const end = new Date(date_range[1]).getTime() + 86400000 - 1;
  const { fetchDistributionReports } = useContactReportService();
  const { fetchTransactionals } = useTransactionalService();
  const { fetchMailActions } = useMailActionService();
  const { fetchReports } = useMailingReportService();
  const { balance: wallet_balance } = useSelector((state) => state.wallet);

  const [transactionals, setTransactionals] = useState([]);
  const [selected_group, setSelectedGroup] = useState(0);
  const [is_loading_email_performance_data, setIsLoadingEmailPerformanceData] = useState(true);
  const [email_performance_data, setEmailPerformanceData] = useState({});
  const [is_loading_click_distribution, setIsLoadingClickDistribution] = useState(true);
  const [is_loading_open_distribution, setIsLoadingOpenDistribution] = useState(true);
  const [is_loading_monthly_report, setIsLoadingMonthlyReport] = useState(true);
  const [open_rate_distribution, setOpenDistribution] = useState([]);
  const [click_rate_distribution, setClickDistribution] = useState([]);
  const [monthly_stat, setMonthlyStat] = useState([]);
  const [transactional_count, setTransactionalCount] = useState({
    total_template: 0,
    total_sent: 0
  });
  const [link_clicks, setLinkClicks] = useState({
    total_clicks: 0,
    avg_click: 0
  });
  const [emails_opens, setEmailOpens] = useState({
    total_opens: 0,
    avg_opens: 0
  });
  const { isMobile } = useScreenSize();

  const chart_key_filters = [
    {
      label: 'Sends',
      value: 'sends',
      default: true,
      color: 'var(--blue-primary)'
    },
    {
      label: 'Bounces',
      value: 'bounces',
      color: 'var(--orange-primary)'
    },
    {
      label: 'Opens',
      value: 'opens',
      default: true,
      color: 'var(--neutral-dark-5)'
    }
  ];

  const report_columns = [
    {
      title: 'Month',
      key: 'month'
    },
    {
      title: 'Templates Created',
      key: 'total_templates'
    },
    {
      title: 'Emails Sent',
      key: 'total_sent'
    },
    {
      title: 'Total Opens',
      key: 'total_opens'
    },
    {
      title: 'Total Clicks',
      key: 'total_clicks'
    },
    {
      title: 'Total Bounces',
      key: 'total_bounces'
    }
  ];

  useEffect(() => {
    fetchTransactionals({ query_string: `time_stamp=${start}~${end}&count=1` }).then(
      ({ error, size: total_template }) => {
        if (error) return toast.error(error);

        setTransactionalCount((curr_count) => ({
          ...curr_count,
          total_template
        }));
      }
    );

    fetchReports({ query_string: `day=${start}~${end}&resource_type=transactional` }).then(
      ({ logs }) => {
        const stats = { total_opens: 0, total_sent: 0, total_click: 0 };
        for (const log of logs) {
          const {
            stats: { sent, opened, clicked_link }
          } = log;
          stats.total_click += clicked_link || 0;
          stats.total_opens += opened || 0;
          stats.total_sent += sent || 0;
        }

        setTransactionalCount((curr) => ({
          ...curr,
          total_sent: stats.total_sent
        }));

        setLinkClicks({
          all_time: stats.total_click,
          total_clicks: stats.total_click,
          avg_click: convertToPercent(stats.total_click, stats.total_sent)
        });

        setEmailOpens({
          total_opens: stats.total_opens,
          avg_opens: convertToPercent(stats.total_opens, stats.total_sent)
        });
      }
    );

    handleEmailPerformanceDateRangeChange();
  }, [start, end, date_range, selected_group]);

  useEffect(() => {
    fetchTransactionals({
      query_string: 'return_only=name,id'
    }).then(({ transactionals, error }) => {
      if (error) toast.error(error);

      const default_group = { label: 'All transactionals', value: 0 };
      const names = [default_group];

      transactionals.forEach((record) => {
        if (!record.name) return;
        names.push({
          label: record.name,
          value: record.id
        });
      });
      setTransactionals(() => names);
    });
  }, []);

  const handleEmailPerformanceDateRangeChange = async () => {
    const ranges = processDateRange(date_range[0], date_range[1]);
    const day_map = ranges.reduce(
      (sac, { label }) => ({
        ...sac,
        [label]: { day: label }
      }),
      {}
    );
    setEmailPerformanceData(() => day_map);

    setIsLoadingEmailPerformanceData(true);
    const id_query = selected_group <= 0 ? '' : `&resource_id=${selected_group}`;

    for (let i = 0; i < ranges.length; i++) {
      const { start, end, label } = ranges[i];
      fetchReports({
        query_string: `day=${start}~${end}&resource_type=transactional${id_query}`
      }).then(({ logs }) => {
        const stats = { bounces: 0, sends: 0, opens: 0 };
        for (const log of logs) {
          const { bounced, opened, sent } = log.stats || {};
          stats.bounces += bounced || 0;
          stats.opens += opened || 0;
          stats.sends += sent || 0;
        }
        setEmailPerformanceData((curr_data) => ({
          ...curr_data,
          [label]: { ...curr_data[label], ...stats }
        }));
      });
    }

    setIsLoadingEmailPerformanceData(false);
  };

  const handleOpenRateDistribution = async (group) => {
    const { value: category } = group;
    setIsLoadingOpenDistribution(() => true);

    const id_query = selected_group <= 0 ? '' : `&resource_id=${selected_group}`;
    const { mailactions, error } = await fetchMailActions({
      query_string: `return_only=recipient_email&resource_type=transactional&action=opened${id_query}`
    });
    if (error) return toast.error(error);
    const contact_ids = mailactions.map((action) => action.recipient_email);
    if (!contact_ids.length) {
      setIsLoadingOpenDistribution(() => false);
      return;
    }

    const { distribution } = await fetchDistributionReports({
      query_string: `category=${category}`,
      data: { ids: contact_ids.join(), type: 'email' }
    });

    setIsLoadingOpenDistribution(() => false);
    setOpenDistribution(() => distribution);
  };

  const handleClickRateDistribution = async (group) => {
    const { value: category } = group;
    setIsLoadingClickDistribution(() => true);
    const id_query = selected_group <= 0 ? '' : `&resource_id=${selected_group}`;

    const { mailactions, error } = await fetchMailActions({
      query_string: `return_only=recipient_email&resource_type=transactional&action=clicked_link${id_query}`
    });
    if (error) return toast.error(error);

    const contact_ids = mailactions.map((action) => action.recipient_email);

    if (!contact_ids.length) {
      setIsLoadingClickDistribution(() => false);
      return;
    }

    const { distribution } = await fetchDistributionReports({
      query_string: `category=${category}`,
      data: { ids: contact_ids.join(), type: 'email' }
    });

    setIsLoadingClickDistribution(() => false);
    setClickDistribution(() => distribution);
  };

  const handleGroupFilterChange = (group) => {
    setSelectedGroup(() => group);
  };

  const handleMonthlyReport = async (group) => {
    const { value: year } = group;
    const monthTimeStamps = getMonthTimestamps(year);
    if (!monthTimeStamps?.length) return;

    try {
      setIsLoadingMonthlyReport(true);
      const monthly_report = [];
      for (const { start, end } of monthTimeStamps) {
        const [{ size: total_transactionals }, { logs }] = await Promise.all([
          fetchTransactionals({
            query_string: `time_stamp=${start}~${end}&count=1`
          }),
          fetchReports({ query_string: `day=${start}~${end}&resource_type=transactional` })
        ]);

        const stats = logs.reduce((sac, log) => {
          const {
            stats: { sent, bounced, opened, clicked_link }
          } = log;
          return {
            total_sent: (sac.total_sent || 0) + (sent || 0),
            total_bounces: (sac.total_bounces || 0) + (bounced || 0),
            total_opens: (sac.total_opens || 0) + (opened || 0),
            total_clicks: (sac.total_clicks || 0) + (clicked_link || 0)
          };
        }, {});

        monthly_report.push({
          month: getMonthNameFromIndex(monthly_report.length),
          total_bounces: toReadableNumber(stats.total_bounces),
          total_clicks: toReadableNumber(stats.total_clicks),
          total_opens: toReadableNumber(stats.total_opens),
          total_sent: toReadableNumber(stats.total_sent),
          total_transactionals: toReadableNumber(total_transactionals)
        });
      }

      setMonthlyStat(monthly_report);
    } catch (error) {
      console.error('Error fetching mail data:', error);
    } finally {
      setIsLoadingMonthlyReport(false);
    }
  };

  const statDetails = [
    {
      main_stat: {
        label: 'Available credits',
        color: 'var(--neutral-light)',
        bg: 'var(--blue-primary)',
        label_color: 'var(--neutral-light)',
        value: wallet_balance
      },
      bottom_stat: {
        label: 'Charge rate:',
        value: 1
      }
    },
    {
      main_stat: { label: 'Total Transactionals', value: transactional_count.total_template },
      bottom_stat: { label: 'Total emails sent:', value: transactional_count.total_sent }
    },
    {
      main_stat: { label: 'Total emails Opens', value: emails_opens.total_opens },
      bottom_stat: { label: 'Avg. open rate', value: emails_opens.avg_opens }
    },
    {
      main_stat: { label: 'Total Link Clicks', value: link_clicks.total_clicks },
      bottom_stat: { label: 'Avg. click rate', value: link_clicks.avg_click }
    }
  ];

  return (
    <>
      <SlidingStatBar>
        {statDetails.map((stat, index) => (
          <PrimaryStatCard key={index} main_stat={stat.main_stat} bottom_stat={stat.bottom_stat} />
        ))}
      </SlidingStatBar>
      <Spacer multiple={4} />

      {!isMobile && (
        <>
          <GridRow>
            <GridColumn span={4}>
              <TimeSeriesChart
                showDatePicker={false}
                data={Object.values(email_performance_data)}
                filter_data={transactionals}
                key_filters={chart_key_filters}
                graph_title="Email Performance"
                is_loading_data={is_loading_email_performance_data}
                onDateRangeChange={handleEmailPerformanceDateRangeChange}
                onGroupChange={handleGroupFilterChange}
              />
            </GridColumn>
          </GridRow>
          <Spacer multiple={4} />
        </>
      )}

      <GridRow tabletStyles={{ gridTemplateColumns: 'repeat(2,1fr)' }}>
        <GridColumn span={2}>
          <DistributionChart
            title="Open rate distribution"
            data={open_rate_distribution}
            is_loading={is_loading_open_distribution}
            onChange={handleOpenRateDistribution}
          />
        </GridColumn>
        <GridColumn span={2}>
          <DistributionChart
            data={click_rate_distribution}
            is_loading={is_loading_click_distribution}
            title="Click rate distribution"
            onChange={handleClickRateDistribution}
          />
        </GridColumn>
      </GridRow>
      <Spacer multiple={4} />
      {!isMobile && (
        <GridRow num_of_columns={1}>
          <ReportTable
            title="Month on Month Report"
            columns={report_columns}
            data={monthly_stat}
            onChange={handleMonthlyReport}
            is_loading={is_loading_monthly_report}
          />
        </GridRow>
      )}
    </>
  );
};
