import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useParams } from 'react-router-dom';
import { setPageTitle } from 'store/actions/header';
import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import { useContactService } from 'hooks/users/contact';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { ContactViewStatBar } from './components/statbar';
import { Spacer } from 'app/layouts/generic';
import { Maillogs } from './components/logs';
import { ClickLogs } from './components/clicks';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import useScreenSize from 'hooks/size';
import ContactDetails from './components/details';

export const ContactPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { fetchContact } = useContactService();
  const { isMobile, isDesktop } = useScreenSize();
  const [contact_data, setContactData] = useState({});

  useEffect(() => {
    if (!id) return;
    fetchContact(id).then((response) => {
      if (!response) return;

      setContactData(() => response);
      const name_parts = [response.firstname, response.lastname].filter(Boolean);
      const full_name = name_parts.length ? name_parts.join(' ') : response.email;

      dispatch(
        setPageTitle([
          { title: 'Contacts', path: '..' },
          { title: full_name || response.email, path: '.' }
        ])
      );
    });
  }, [id]);

  const nav_config = [
    { title: 'Details', path: `/contacts/${id}` },
    { title: 'Email logs', path: `/contacts/${id}/emails` },
    { title: 'Click logs', path: `/contacts/${id}/clicks` }
  ];

  return (
    <div className="dt-content-wrapper">
      <PageContentWrapper>
        <GridRow>
          {isDesktop && (
            <GridColumn>
              <ContactDetails contact_data={contact_data} setContactData={setContactData} />
            </GridColumn>
          )}
          <GridColumn span={isDesktop ? 3 : 4}>
            {!isDesktop && <SlidingNav nav_items={nav_config} />}
            {!isMobile && <ContactViewStatBar />}
            {isDesktop && <SlidingNav nav_items={nav_config.slice(1)} />}
            <Spacer multiple={4} />
            <Routes>
              <Route path="clicks" element={<ClickLogs email={contact_data.email} />} />
              <Route path="emails" element={<Maillogs email={contact_data.email} />} />
              <Route
                path="/*"
                element={
                  isDesktop ? (
                    <Maillogs email={contact_data.email} />
                  ) : (
                    <ContactDetails contact_data={contact_data} setContactData={setContactData} />
                  )
                }
              />
            </Routes>
          </GridColumn>
        </GridRow>
      </PageContentWrapper>
    </div>
  );
};
