import cx from 'classnames';
import { Icon } from 'assets/icons';
import { TreeContext } from 'contexts/automation';
import { useResourceQuery } from 'hooks/automation/resources';
import { useAutomationStepService } from 'hooks/automation/steps';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Handle, Position } from 'reactflow';
import { serializeAction } from './serializer';
import styles from './widget.module.css';
import { truncateText } from 'app/shared/utils/general';
import { toast } from 'react-toastify';
import { useAutomationJourneyService } from 'hooks/automation/journey';
import { toReadableNumber } from 'app/shared/utils/number';

export const TriggerBranchWidget = (props) => {
  const { id: source_handle_id, isConnectable, data } = props;
  const { setWidgetToCreate, toggleStepModal } = useContext(TreeContext);

  const handleAdd = () => {
    setWidgetToCreate(() => '');
    const branch_value = source_handle_id.split('_')[1].toLowerCase();
    const parents = branch_value ? [data.id, `${data.id}_${branch_value}`] : [data.id];
    toggleStepModal({
      branch_value,
      parents,
      template_id: data.template_id,
      source: data.source,
      show: true,
      source_widget: 'trigger'
    });
  };

  return (
    <>
      <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
      <span className={cx(styles.addBtn)}>
        <Icon name="add" onClick={handleAdd} />
      </span>
      <Handle
        type="source"
        position={Position.Bottom}
        id={source_handle_id}
        isConnectable={isConnectable}
      />
    </>
  );
};

export const AutomationTriggerWidget = (props) => {
  const { id: source_handle_id, isConnectable, data } = props;
  const { fetchResources } = useResourceQuery();
  const { fetchJourneys } = useAutomationJourneyService();
  const { deleteStep } = useAutomationStepService();
  const { setWidgetToCreate, toggleStepModal } = useContext(TreeContext);
  const steps_in_store = useSelector((store) => store.steps);

  const [stats, setStats] = useState({});
  const [resource_name, setResourceName] = useState('');
  const [root_steps, setRootSteps] = useState([]);

  useEffect(() => {
    setRootSteps(() => Object.values(steps_in_store).filter((step) => step.is_root));
  }, [steps_in_store]);

  useEffect(() => {
    if (!data.event || !data.source) return;
    const { id } = data;
    const [resource_class, resource_id] = data.source.split(':');

    fetchJourneys({ query_string: `possible_next_steps=${id}&status=started&count=1` }).then(
      ({ size: active_journeys, error }) => {
        if (error) return toast.error(error);

        setStats((curr_stat) => ({ ...curr_stat, active: active_journeys }));
      }
    );

    fetchResources(resource_class, '', {
      query_string: `id=${resource_id}&return_only=name,title,link`
    }).then(({ resources, error }) => {
      if (error) return;

      const [resource] = resources;
      setResourceName(() => resource.name || resource.title || resource.link);
    });
  }, []);

  const handleAdd = () => {
    setWidgetToCreate(() => '');
    toggleStepModal({
      branch_value: data.branch_value,
      template_id: data.template_id,
      parents: [data.id],
      source: data.source,
      show: true,
      rules: [],
      source_widget: 'trigger'
    });
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    if (data.is_root && root_steps.length === 1) {
      toast.error('Template must have at least one root step.');
      return;
    }

    await deleteStep(data.id);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    if (!data.is_root && data.children.length > 0) return;
    setWidgetToCreate('trigger');
    toggleStepModal({
      template_id: data.template_id,
      parents: data.parents || [],
      show: true,
      mode: 'edit',
      data
    });
  };

  return (
    <div className={styles.widget}>
      {!data.is_root && (
        <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
      )}
      <div className={styles.wrapper}>
        <div className={styles.content} onClick={handleEdit}>
          <span>When a contact {serializeAction(data.event, truncateText(resource_name, 30))}</span>
          <span className={styles.icon}>
            <Icon name="trash" onClick={handleDelete} />
          </span>
        </div>
        <div className={styles.statWrapper}>
          <span>Active Journeys: {toReadableNumber(stats.active)}</span>
        </div>
      </div>
      {!data.is_conditional && !data.is_root && (
        <>
          <span id={styles.vertLine} />
          <span className={styles.addBtn}>
            <Icon name="add" onClick={handleAdd} />
          </span>
        </>
      )}
      <Handle
        type="source"
        position={Position.Bottom}
        id={source_handle_id}
        isConnectable={isConnectable}
      />
    </div>
  );
};
