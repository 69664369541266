import { GridColumn, GridRow } from 'app/layouts/grid';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { toReadableNumber } from 'app/shared/utils/number';
import { useAutomationJourneyService } from 'hooks/automation/journey';
import React, { useEffect, useState } from 'react';

export const AutomationTemplateStat = ({ template_id }) => {
  const { fetchJourneys } = useAutomationJourneyService();
  const [stat, setStat] = useState({});

  useEffect(() => {
    if (!template_id || isNaN(template_id)) return;
    Promise.all([
      fetchJourneys({ query_string: `status=started&template_id=${template_id}&count=1` }),
      fetchJourneys({ query_string: `status=completed&template_id=${template_id}&count=1` })
    ]).then(([{ size: active }, { size: completed }]) => {
      setStat(() => ({ active, completed }));
    });

    return () => {
      setStat(() => ({}));
    };
  }, [template_id]);

  return (
    <BasicInfoSection title="Journey stats">
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Active Journeys">{toReadableNumber(stat.active)}</DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Completed Journeys">{toReadableNumber(stat.completed)}</DetailItem>
        </GridColumn>
      </GridRow>
    </BasicInfoSection>
  );
};
