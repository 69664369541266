import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PrimaryStatCard } from 'app/shared/cards';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { useContactService } from 'hooks/users/contact';
import { useMailActionService } from 'hooks/mailaction';
import { formatDateForDisplay, getMonthTimestamps, getYearRange } from 'app/shared/utils/date';
import { convertToPercent, getMonthNameFromIndex } from 'app/shared/utils/general';
import { toReadableNumber } from 'app/shared/utils/number';
import { Spacer } from 'app/layouts/generic';
import { TimeSeriesChart, processDateRange } from 'app/shared/charts/timeseries';
import { DistributionChart } from 'app/shared/charts/distribution';
import { ReportTable } from 'app/shared/datatable/simple';
import useScreenSize from 'hooks/size';
import { SlidingStatBar } from 'app/layouts/stats/sliding';
import { toast } from 'react-toastify';
import { useContactReportService } from 'hooks/users/report';

export const ContactsDashboardSection = ({ date_range = [] }) => {
  const start = new Date(date_range[0]).getTime();
  const end = new Date(date_range[1]).getTime() + 86400000 - 1;
  const { balance: wallet_balance } = useSelector((state) => state.wallet);
  const { fetchContacts } = useContactService();
  const { fetchDistributionReports, fetchReports } = useContactReportService();
  const { fetchMailActions } = useMailActionService();
  const { isMobile } = useScreenSize();

  const [last_created_at, setLastCreatedAt] = useState('');
  const [total_contacts, setTotalContacts] = useState(0);
  const [total_active, setTotalActive] = useState({
    all_time: 0,
    growth_rate: 0
  });
  const [total_invalid, setTotalInvalid] = useState({
    all_time: 0,
    burn_rate: 0
  });
  const [growth_report_data, setGrowthReportData] = useState({});
  const [is_loading_growth_report_data, setIsLoadingGrowthReportData] = useState(true);
  const [is_loading_demographic_distribution, setIsLoadingDemographicDistribution] = useState(true);
  const [is_loading_activity_distribution, setIsLoadingActivityDistribution] = useState(true);
  const [demographic_rate_distribution, setDemographicDistribution] = useState([]);
  const [activity_rate_distribution, setActivityDistribution] = useState([]);
  const [is_loading_monthly_report, setIsLoadingMonthlyReport] = useState(true);
  const [monthly_report_stat, setMonthlyReportStat] = useState([]);

  const chart_key_filters = [
    {
      label: 'Subscriptions',
      value: 'subscriptions',
      default: true,
      color: 'var(--green-primary)'
    },
    {
      label: 'Unsubscriptions',
      value: 'unsubscriptions',
      default: true,
      color: 'var(--red-primary)'
    }
  ];

  const report_columns = [
    {
      title: 'Month',
      key: 'month'
    },
    {
      title: 'Subscriptioins',
      key: 'subscriptions'
    },
    {
      title: 'Unsubscriptions',
      key: 'unsubscriptions'
    },
    {
      title: 'Growth rate',
      key: 'growth_rate',
      style: (value) => ({
        color: value < 0 ? 'red' : value > 0 ? 'green' : 'black'
      }),
      suffix: '%'
    }
  ];

  useEffect(() => {
    Promise.all([
      fetchContacts({ query_string: `count=1` }),
      fetchContacts({ query_string: `time_stamp=${start}~${end}&count=1` }),
      fetchContacts({ query_string: `time_stamp=${start}~${end}&bool=-is_valid&count=1` }),
      fetchContacts({
        query_string: `unsubscribed_on=${start}~${end}&bool=is_unsubscribed&count=1`
      }),
      fetchContacts({
        query_string: `time_stamp=${start}~${end}&sort_by=-created_on&page=0&population=1&return_only=id,created_on`
      }),
      fetchMailActions({
        query_string: `time_stamp=${start}~${end}&action=opened,clicked_link&group_by=recipient_id&count=1`
      })
    ])
      .then((responses) => {
        const [
          { size: total_contacts },
          { size: contact_count },
          { size: invalid_contact_count },
          { size: unsubscriptions_month },
          {
            contacts: [contact]
          },
          { mailactions: all_active }
        ] = responses;
        setTotalContacts(() => contact_count || 0);

        if (contact) {
          setLastCreatedAt(contact.created_on);
        }
        setTotalActive(() => ({
          all_time: all_active[0] ? all_active[0].size : 0,
          growth_rate: convertToPercent(contact_count, total_contacts) || 0
        }));

        setTotalInvalid(() => ({
          all_time: invalid_contact_count || 0,
          burn_rate: convertToPercent(unsubscriptions_month, total_contacts) || 0
        }));
      })
      .catch(() => toast.error('Unable to fetch logs at this time.'));
  }, [start, end, date_range]);

  const handleDemographicRateDistribution = async (group) => {
    const { value: category } = group;
    setIsLoadingDemographicDistribution(() => true);

    const { contacts } = await fetchContacts({
      query_string: `return_only=id`
    });
    const contact_ids = contacts.map((action) => action.id);

    if (!contact_ids.length) {
      setIsLoadingDemographicDistribution(() => false);
      return;
    }

    const { distribution } = await fetchDistributionReports({
      query_string: `category=${category}`,
      data: { ids: contact_ids.join() }
    });

    setIsLoadingDemographicDistribution(() => false);
    setDemographicDistribution(() => distribution);
  };

  const handleActivityRateDistribution = async (group) => {
    const { value: category } = group;
    setIsLoadingActivityDistribution(() => true);

    const { mailactions } = await fetchMailActions({
      query_string: `action=opened,clicked_link?return_only=recipient_email`
    });

    const contact_ids = mailactions.map((action) => action.recipient_email);

    if (!contact_ids.length) {
      setIsLoadingActivityDistribution(() => false);
      return;
    }

    const { distribution } = await fetchDistributionReports({
      query_string: `category=${category}`,
      data: { ids: contact_ids.join(), type: 'email' }
    });

    setIsLoadingActivityDistribution(() => false);
    setActivityDistribution(() => distribution);
  };

  const handleGrowthRateDateRangeChange = useCallback(async () => {
    const ranges = processDateRange(date_range[0], date_range[1]);
    const day_map = ranges.reduce(
      (sac, { label }) => ({
        ...sac,
        [label]: { day: label }
      }),
      {}
    );
    setGrowthReportData(() => day_map);

    setIsLoadingGrowthReportData(true);
    for (let i = 0; i < ranges.length; i++) {
      const { start, end, label } = ranges[i];
      const { reports } = await fetchReports({ query_string: `day=${start}~${end}` });

      const stats = { subscriptions: 0, unsubscriptions: 0 };
      for (const report of reports) {
        const { stats: report_stat } = report;
        stats.subscriptions += report_stat.subscriptions || 0;
        stats.unsubscriptions += report_stat.unsubscriptions || 0;
      }

      setGrowthReportData((curr_data) => ({
        ...curr_data,
        [label]: {
          ...curr_data[label],
          subscriptions: stats.subscriptions,
          unsubscriptions: stats.unsubscriptions
        }
      }));
    }
    setIsLoadingGrowthReportData(false);
  }, [date_range]);

  const handleMonthlyReport = async (group) => {
    const { value: year } = group;
    const { startDate, endDate } = getYearRange(year);
    const monthTimeStamps = getMonthTimestamps(year);

    try {
      setIsLoadingMonthlyReport(true);
      const monthly_report = [];

      for (const { start, end } of monthTimeStamps) {
        const { size: created_this_year } = await fetchContacts({
          query_string: `time_stamp=${startDate}~${endDate}&count=1`
        });

        const [{ size: subscriptions }, { size: unsubscriptions }] = await Promise.all([
          fetchContacts({
            query_string: `time_stamp=${start}~${end}&count=1`
          }),
          fetchContacts({
            query_string: `unsubscribed_on=${start}~${end}&bool=is_unsubscribed&count=1`
          })
        ]).catch(() => toast.error('Unable to fetch logs at this time.'));

        monthly_report.push({
          month: getMonthNameFromIndex(monthly_report.length),
          subscriptions: toReadableNumber(subscriptions),
          unsubscriptions: toReadableNumber(unsubscriptions),
          growth_rate: toReadableNumber((subscriptions / created_this_year) * 100)
        });
      }

      setMonthlyReportStat(monthly_report);
    } catch (error) {
      console.error('Error fetching mail data:', error);
    } finally {
      setIsLoadingMonthlyReport(false);
    }
  };

  useEffect(() => {
    handleGrowthRateDateRangeChange();
  }, [date_range]);

  const statDetails = [
    {
      main_stat: {
        label: 'Available credits',
        color: 'var(--neutral-light)',
        bg: 'var(--blue-primary)',
        label_color: 'var(--neutral-light)',
        value: wallet_balance
      },
      bottom_stat: {
        label: 'Charge rate:',
        value: 1
      }
    },
    {
      main_stat: { label: 'Total Contacts', value: total_contacts },
      bottom_stat: { label: 'Last created at', value: formatDateForDisplay(last_created_at) }
    },
    {
      main_stat: {
        label: 'Active Contacts',
        value: total_active.all_time
      },
      bottom_stat: { label: 'Growth rate:', value: total_active.growth_rate }
    },
    {
      main_stat: {
        label: 'Total invalid',
        value: total_invalid.all_time
      },
      bottom_stat: { label: 'Burn Rate: ', value: total_invalid.burn_rate }
    }
  ];

  return (
    <>
      <SlidingStatBar>
        {statDetails.map((stat, index) => (
          <PrimaryStatCard key={index} main_stat={stat.main_stat} bottom_stat={stat.bottom_stat} />
        ))}
      </SlidingStatBar>
      <Spacer multiple={4} />

      {!isMobile && (
        <>
          <GridRow>
            <GridColumn span={4}>
              <TimeSeriesChart
                showDatePicker={false}
                showFilter={false}
                data={Object.values(growth_report_data)}
                key_filters={chart_key_filters}
                graph_title="Growth Report"
                is_loading_data={is_loading_growth_report_data}
              />
            </GridColumn>
          </GridRow>
          <Spacer multiple={4} />
        </>
      )}

      <GridRow num_of_columns={2} tabletStyles={{ gridTemplateColumns: 'repeat(1,1fr)' }}>
        <GridColumn>
          <GridRow num_of_columns={1}>
            <DistributionChart
              title="Demographic distribution"
              data={demographic_rate_distribution}
              is_loading={is_loading_demographic_distribution}
              onChange={handleDemographicRateDistribution}
            />
          </GridRow>
          <Spacer multiple={4} />
          <GridRow num_of_columns={1}>
            <DistributionChart
              title="Activity distribution"
              data={activity_rate_distribution}
              is_loading={is_loading_activity_distribution}
              onChange={handleActivityRateDistribution}
            />
          </GridRow>
        </GridColumn>
        {!isMobile && (
          <GridColumn>
            <ReportTable
              title="Month on Month Report"
              columns={report_columns}
              data={monthly_report_stat}
              onChange={handleMonthlyReport}
              is_loading={is_loading_monthly_report}
            />
          </GridColumn>
        )}
      </GridRow>
    </>
  );
};
