/** */
export const READ_MANY_EVENT = 'read multiple event.';
export const ADD_MANY_EVENT_TO_STORE = 'add multiple event to store.';
export const LOAD_EVENTS_TO_STORE = 'load events to store from API call';
export const REMOVE_ONE_EVENT_FROM_STORE = 'remove one event from store';
export const REMOVE_MANY_EVENT_FROM_STORE = 'remove many event from store';
export const ADD_ONE_EVENT_TO_STORE = 'add one event to store';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add event search results to store.';
export const UPDATE_ONE_EVENT_IN_STORE = 'update an event in store.';

/** */
export const readManyEvent = (payload) => ({
  type: READ_MANY_EVENT,
  payload
});

export const addManyEventToStore = (payload) => ({
  type: ADD_MANY_EVENT_TO_STORE,
  payload
});

export const removeOneEventFromStore = (payload) => ({
  type: REMOVE_ONE_EVENT_FROM_STORE,
  payload
});

export const removeManyEventFromStore = (payload) => ({
  type: REMOVE_MANY_EVENT_FROM_STORE,
  payload
});

export const addOneEventToStore = (payload) => ({
  type: ADD_ONE_EVENT_TO_STORE,
  payload
});

export const addEventSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadEventsToStore = (payload) => ({
  type: LOAD_EVENTS_TO_STORE,
  payload
});

export const updateEventInStore = (payload) => ({
  type: UPDATE_ONE_EVENT_IN_STORE,
  payload
});
