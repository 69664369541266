/** */
export const READ_MANY_AUDIENCES = 'read multiple AUDIENCES.';
export const READ_ONE_AUDIENCE = 'read one AUDIENCE.';

export const ADD_ONE_AUDIENCE_TO_STORE = 'add one AUDIENCE to store.';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add AUDIENCE search results to store.';
export const ADD_MANY_AUDIENCES_TO_STORE = 'add multiple AUDIENCES to atore.';
export const LOAD_AUDIENCES = 'load up audiences from API';
export const REMOVE_ONE_AUDIENCE_FROM_STORE = 'remove a audience from the store.';
export const UPDATE_AUDIENCE_IN_STORE = 'update an audience in the store.';

/** */
export const readManyAudiences = (payload) => ({
  type: READ_MANY_AUDIENCES,
  payload
});

export const readOneAudience = (payload) => ({
  type: READ_ONE_AUDIENCE,
  payload
});

export const addManyAudiencesToStore = (payload) => ({
  type: ADD_MANY_AUDIENCES_TO_STORE,
  payload
});

export const addOneAudienceToStore = (payload) => ({
  type: ADD_ONE_AUDIENCE_TO_STORE,
  payload
});

export const addAudienceSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadAudienceToStore = (payload) => ({
  type: LOAD_AUDIENCES,
  payload
});

export const removeOneAudienceFromStore = (payload) => ({
  type: REMOVE_ONE_AUDIENCE_FROM_STORE,
  payload
});

export const updateAudienceInStore = (payload) => ({
  type: UPDATE_AUDIENCE_IN_STORE,
  payload
});
