import { toast } from 'react-toastify';
import {
  EVENT_BIRTHDAY,
  EVENT_CLICKED_LINK,
  EVENT_NEW_CONTACT
} from '@go-mailer/jarvis/lib/constants/automation';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { MultiSelect } from 'app/shared/select';
import { TreeContext } from 'contexts/automation';
import { useEventsService } from 'hooks/automation/event';
import { determineEvents, useResourceQuery } from 'hooks/automation/resources';
import { useAutomationStepService } from 'hooks/automation/steps';
import React, { useContext, useEffect, useState } from 'react';
import styles from './config.module.css';
import { useSelector } from 'react-redux';
import { LinkTriggerConfig } from './link';

const conditional_options = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

const root_exclusions = {};

export const AutomationTriggerConfig = () => {
  const { step_modal, toggleStepModal } = useContext(TreeContext);

  const { fetchEvents, isCustomEvent } = useEventsService();
  const { fetchResources } = useResourceQuery();
  const { createStep, fetchSteps, updateStep } = useAutomationStepService();
  const steps_in_store = useSelector((store) => store.steps);

  const [events, setEvents] = useState([]);
  const [creating, setCreating] = useState(false);
  const [parent, setParent] = useState(null);
  const [resources, setResources] = useState([]);
  const [selected_condition, setSelectedConditional] = useState(null);
  const [selected_resource, setSelectedResource] = useState(null);
  const [selected_trigger, setSelectedTrigger] = useState(null);
  const [show_link_config, setShowLinkConfig] = useState(false);

  //
  const is_root = !step_modal.parents || !step_modal.parents[0];
  const is_edit_mode = step_modal.mode === 'edit';

  useEffect(() => {
    const parent_id = step_modal.parents ? step_modal.parents[0] : undefined;
    const parent_data = steps_in_store[parent_id] || null;
    if (parent_data) {
      setParent(() => parent_data);
    }

    fetchEvents().then(({ events: raw_events, error }) => {
      if (error) return toast.error(error);

      let parsed_events = raw_events.map((ev) => ({ ...ev, label: ev.name, value: ev.code }));
      if (is_root) {
        parsed_events = parsed_events.filter((ev) => !root_exclusions[ev.resource_class]);
      }

      const possible_events = determineEvents(parsed_events, parent_data);
      setEvents(() => possible_events);
    });

    return () => {
      setParent(null);
    };
  }, [step_modal.parents]);

  useEffect(() => {
    if (!events.length || !is_edit_mode) return;
    const { data } = step_modal;
    const { event: trigger, source } = data;
    const [resource_class, resource_id] = source.split(':');

    if (!trigger || !resource_class || !resource_id) return;
    setSelectedTrigger(() => events.find((event) => event.code === trigger) || {});
  }, [step_modal.mode, events]);

  useEffect(() => {
    if (!selected_trigger) return;
    setShowLinkConfig(() => selected_trigger.value === EVENT_CLICKED_LINK);
    if (!parent && selected_trigger.value === EVENT_CLICKED_LINK) return;
    if (selected_trigger.value === EVENT_NEW_CONTACT) return setSelectedResource({ value: 'none' });
    if (selected_trigger.value === EVENT_BIRTHDAY) return setSelectedResource({ value: 'any' });
    if (isCustomEvent(selected_trigger)) return setSelectedResource({ value: 'any' });

    handleResourceRequest(selected_trigger, parent);
  }, [selected_trigger]);

  const can_select_resource =
    is_root ||
    (selected_trigger && selected_trigger.value === EVENT_CLICKED_LINK) ||
    (selected_trigger && isCustomEvent(selected_trigger));

  const is_conditional_trigger = !is_root && selected_trigger && selected_trigger.is_conditional;

  const handleLinkConfig = (config = {}) => {
    handleResourceRequest(selected_trigger, config);
  };

  const handleTriggerSelection = (selection = null) => {
    if (!selection) return;
    setSelectedTrigger(selection);
  };

  const handleResourceRequest = (trigger, parent = null) => {
    let query_string = 'return_only=name,id,link';
    if (trigger.value === EVENT_CLICKED_LINK) {
      const [resource_type, resource_id] = parent.target.split(':');
      query_string = `${query_string}&resource_type=${resource_type}&resource_id=${resource_id}`;
    }

    if (trigger.resource_class === 'campaign') {
      query_string = `${query_string}&bool=-is_test_campaign`;
    }

    fetchResources(trigger.resource_class, '', { query_string }).then(
      ({ resources: raw_resources, error }) => {
        if (error) return;

        const parsed_resources = raw_resources.map((res) => ({
          ...res,
          label: res.name || res.title || res.link,
          value: res.id
        }));

        setResources(() => parsed_resources);

        if (is_edit_mode && trigger.value === step_modal.data.event) {
          const { data } = step_modal;
          const [, resource_id] = data.source.split(':');
          if (isNaN(resource_id)) return;
          setSelectedResource(
            () =>
              parsed_resources.find((resource) => Number(resource.value) === Number(resource_id)) ||
              {}
          );
        }
      }
    );
  };

  const handleResourceSelection = (selection = null) => {
    if (!selection) return;
    setSelectedResource(selection);
  };

  const submit = async () => {
    let parent_id = !is_root && parent ? parent.id : undefined;

    if (step_modal.type === 'exit') {
      parent_id = 0;
      const { size, error } = await fetchSteps({
        query_string: `template_id=${step_modal.template_id}&type=exit&count=1`
      });
      if (error) return toast.error(error);

      if (size > 2) return toast.info('Cannot set more 3 exit triggers');
    }

    const source =
      parent && selected_trigger.value !== EVENT_CLICKED_LINK
        ? parent.target
        : `${selected_trigger.resource_class}:${selected_resource.value}`;

    const data = {
      event: selected_trigger.value,
      is_conditional: !is_root && selected_condition ? selected_condition.value : false,
      parents: is_root ? [] : [parent_id],
      source,
      template_id: step_modal.template_id,
      type: step_modal.type || 'trigger',
      children: is_root ? ['rootconnector'] : []
    };

    const step_id = is_edit_mode ? step_modal.data.id : null;

    try {
      setCreating(() => true);
      const steps_response = await fetchSteps({
        query_string: `event=${data.event}&bool=is_root&template_id=${data.template_id}`
      });

      if (!steps_response) throw new Error('');
      const { steps: matching_steps, error: e } = steps_response;
      if (e) return toast.error(e);

      if (matching_steps.length > 0) {
        const saved_sources = matching_steps.map((step) => step.source);
        if (saved_sources.includes(source)) {
          toast.error('Trigger already configured.');
          return setCreating(() => false);
        }
      }
      const result = step_id ? await updateStep(step_id, data) : await createStep(data);

      if (result) {
        setSelectedResource({});
        setSelectedTrigger({});
        toggleStepModal({ show: false });
      }
    } finally {
      setCreating(() => false);
    }
  };

  return (
    <div className={styles.formWrapper}>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Trigger">
            <MultiSelect
              options={events}
              onChange={handleTriggerSelection}
              value={selected_trigger}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      {is_root && show_link_config && <LinkTriggerConfig onSelection={handleLinkConfig} />}
      {can_select_resource && (
        <GridRow num_of_columns={1}>
          <GridColumn>
            <DetailItem title="Resource">
              <MultiSelect
                options={resources}
                onChange={handleResourceSelection}
                value={selected_resource}
                disabled={
                  selected_trigger?.value === EVENT_BIRTHDAY ||
                  selected_trigger?.value === EVENT_NEW_CONTACT ||
                  isCustomEvent(selected_trigger)
                }
              />
            </DetailItem>
          </GridColumn>
        </GridRow>
      )}
      {is_conditional_trigger && (
        <GridRow num_of_columns={1}>
          <GridColumn>
            <DetailItem title="Is Conditional">
              <MultiSelect
                options={conditional_options}
                value={selected_condition}
                onChange={setSelectedConditional}
              />
            </DetailItem>
          </GridColumn>
        </GridRow>
      )}
      <GridRow num_of_columns={3}>
        <GridColumn />
        <GridColumn />
        <GridColumn>
          <Button text="Save" disabled={creating} onClick={submit} />
        </GridColumn>
      </GridRow>
    </div>
  );
};
