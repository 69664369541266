/** */
import { urls } from 'services/_url';
import { apiGet, apiPost } from 'services/_http';

/** */
const base_url = `${urls.users}/contacts/reports`;

export const read = async (options = {}) => {
  return await apiGet(`${base_url}/all`, { ...options });
};

export const readDistributionReports = async (options = {}) => {
  return await apiPost(`${base_url}/distribution?${options.query_string}`, { ...options });
};

export const readComparativeReport = async (options = {}) => {
  return await apiGet(`${base_url}/comparative`, { ...options });
};

export const readPerformanceReport = async (options = {}) => {
  return await apiGet(`${base_url}/performance`, { ...options });
};
