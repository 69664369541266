/** */
import {
  ADD_MANY_AUDIENCES_TO_STORE,
  ADD_ONE_AUDIENCE_TO_STORE,
  ADD_SEARCH_RESULTS_TO_STORE,
  LOAD_AUDIENCES,
  REMOVE_ONE_AUDIENCE_FROM_STORE,
  UPDATE_AUDIENCE_IN_STORE
} from '../actions/audience';

export default (state = {}, action) => {
  const { type, payload } = action;
  const current_state = state;
  switch (type) {
    case ADD_MANY_AUDIENCES_TO_STORE:
      return {
        ...state,
        ...payload.reduce(
          (audiences, audience) => ({
            ...audiences,
            [audience.id]: audience
          }),
          {}
        )
      };
    case ADD_SEARCH_RESULTS_TO_STORE:
      return {
        ...payload.reduce(
          (audiences, audience) => ({
            ...audiences,
            [audience.id]: audience
          }),
          {}
        )
      };
    case ADD_ONE_AUDIENCE_TO_STORE:
      return {
        ...state,
        [payload.id]: payload
      };
    case LOAD_AUDIENCES:
      return {
        ...payload.reduce(
          (audiences, audience) => ({
            ...audiences,
            [audience.id]: audience
          }),
          {}
        )
      };
    case REMOVE_ONE_AUDIENCE_FROM_STORE:
      delete current_state[payload];
      return { ...current_state };
    case UPDATE_AUDIENCE_IN_STORE:
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          ...payload
        }
      };
    default:
      return state;
  }
};
