import { Spacer } from 'app/layouts/generic';
import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import { PermissionsContext } from 'contexts/permissions';
import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { EventModule } from './event';
import { NeuronModule } from './neurons';
import { WorkflowModule } from './workflows';

const nav_items = (permissions = {}) => [
  {
    title: 'Workflows',
    path: `/automations/workflows`
  },
  {
    title: 'Neurons',
    path: `/automations/neurons`
  },
  {
    title: 'Events',
    path: `/automations/events`,
    hidden: !permissions['automation_event:view']
  }
];

export const AutomationModule = () => {
  const { permissions } = useContext(PermissionsContext);
  return (
    <PageContentWrapper>
      <SlidingNav nav_items={nav_items(permissions)} />
      <Spacer multiple={4} />
      <Routes>
        {permissions['automation_event:view'] && (
          <Route exact path="events">
            <Route index element={<EventModule />} />
          </Route>
        )}
        <Route path="neurons" element={<NeuronModule />} />
        <Route path="workflows" element={<WorkflowModule />} />
        <Route path="/*" element={<WorkflowModule />} />
      </Routes>
    </PageContentWrapper>
  );
};
