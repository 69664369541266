import { Button } from 'app/shared/button';
import { useSubscriptionService } from 'hooks/sales/subscription';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { setPageTitle } from 'store/actions/header';
import { SMSListPage } from './list';
import styles from './sms.module.css';

const NotActive = () => {
  const navigateTo = useNavigate();
  return (
    <div className={styles.nocontentwrapper}>
      <div className={styles.action}>
        <Button text="Activate SMS Messaging" onClick={() => navigateTo('/subscriptions')} />
      </div>
    </div>
  );
};

export const SMSModule = () => {
  const dispatch = useDispatch();
  const { fetchSubscription } = useSubscriptionService();

  const [has_subscription, setHasSubscription] = useState(false);
  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Messaging', path: '/messaging/sms' },
        { title: 'SMS', path: '.' }
      ])
    );

    fetchSubscription({ query_string: 'plan_code=sms_termii&status=active' }).then(({ size }) => {
      setHasSubscription(!!size);
    });
  }, []);

  return (
    <>
      {!has_subscription ? (
        <NotActive />
      ) : (
        <Routes>
          <Route path="/*" element={<SMSListPage />} />
        </Routes>
      )}
    </>
  );
};
