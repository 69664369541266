import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { useMailActionService } from 'hooks/mailaction';
import { useSelector } from 'react-redux';
import { useCampaignService } from 'hooks/mailing/campaign';
import { useTransactionalService } from 'hooks/transactional';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import WebDatatable from 'app/shared/datatable/web/datatable';
import { useContactService } from 'hooks/users/contact';
import { Badge } from 'app/shared/badge';
import { useLinksService } from 'hooks/link';
import { formatDateForDisplay } from 'app/shared/utils/date';
import { truncateText } from 'app/shared/utils/general';
import { toReadableNumber } from 'app/shared/utils/number';
import { toast } from 'react-toastify';

export const ClickLogs = ({ email }) => {
  const { id: contact_id } = useParams();
  const { is_mobile_view } = useSelector((state) => state.metadata);

  const { fetchCampaigns } = useCampaignService();
  const { fetchContact } = useContactService();
  const { fetchLinks } = useLinksService();
  const { fetchMailActions } = useMailActionService();
  const { fetchTransactionals } = useTransactionalService();

  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState({});
  const [total_logs, setTotalLogs] = useState(0);

  const config = {
    actions: {
      single: () => []
    },
    css: {},
    fields: [
      {
        title: 'Link',
        key: 'link',
        isTitle: true,
        formatter: (value) => truncateText(value.link, 25) || 'N/A'
      },
      {
        title: 'Resource name',
        key: 'resource',
        isTagline: true,
        formatter: (value) => (value ? truncateText(value.name || value.title, 30) : 'N/A')
      },
      {
        title: 'Clicked at',
        key: 'created_on',
        isMetadata: true,
        formatter: (value) => formatDateForDisplay(value)
      },
      {
        title: 'Resource type',
        key: 'resource_type',
        formatter: (value) => <Badge text={value} type={value === 'campaign' ? 'blue' : 'orange'} />
      },
      {
        title: 'Click count',
        key: 'occurences',
        formatter: (value) => toReadableNumber(value.length || 0)
      }
    ],
    is_search_mode,
    items: Object.values(logs).sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'name',
    search_text: ''
  };

  const handleDataRequest = async (page, population = 50) => {
    setLoading(true);
    let contact_email = email;
    if (!contact_email) {
      const contact = await fetchContact(contact_id);
      contact_email = contact.email;
    }

    const { mailactions, size, error } = await fetchMailActions({
      query_string: `recipient_email=${encodeURIComponent(
        contact_email
      )}&action=clicked_link&page=${page}&population=${population}`
    });
    if (error) return toast.error(error);

    const campaign_actions_by_id = {};
    const transactional_actions_by_id = {};
    let link_by_id = {};

    mailactions.forEach((action) => {
      if (action.resource_type === 'campaign') {
        campaign_actions_by_id[action.resource_id] = action;
      } else {
        transactional_actions_by_id[action.resource_id] = action;
      }

      const { metadata } = action;
      if (!metadata) return;
      link_by_id[metadata.link_id] = metadata.link_id;
    });

    const [{ links }, { campaigns }, { transactionals }] = await Promise.all([
      fetchLinks({ query_string: `id=${Object.keys(link_by_id).join()}` }),
      fetchCampaigns({ query_string: `id=${Object.keys(campaign_actions_by_id).join()}` }),
      fetchTransactionals({ query_string: `id=${Object.keys(transactional_actions_by_id).join()}` })
    ]).catch(() => toast.error('Unable to fetch data at this time.'));

    link_by_id = links.reduce((s, l) => ({ ...s, [l.id]: { ...l } }), {});

    const campaigns_by_id = campaigns.reduce((s, c) => ({ ...s, [c.id]: { ...c } }), {});
    const transactionals_by_id = transactionals.reduce((s, t) => ({ ...s, [t.id]: { ...t } }), {});

    const parsed_logs = mailactions.reduce(
      (sac, log) => ({
        ...sac,
        [log.id]: {
          ...log,
          link: log.metadata ? link_by_id[log.metadata.link_id] : 'N/A',
          resource:
            (log.resource_type === 'campaign'
              ? campaigns_by_id[log.resource_id]
              : transactionals_by_id[log.resource_id]) || {}
        }
      }),
      {}
    );

    setLogs((curr_logs) => ({ ...curr_logs, ...parsed_logs }));
    setTotalLogs(() => size);
    setLoading(false);
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {};

  return (
    <GridRow>
      <GridColumn span={4}>
        {is_mobile_view ? (
          <MobileDatatable
            config={config}
            onListModeChange={setSearchMode}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
            showHeader
          />
        ) : (
          <WebDatatable
            config={{
              ...config,
              num_of_rows: 15,
              total_count: total_logs
            }}
            checkbox
            loading_data={loading}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
            showHeader={false}
          />
        )}
      </GridColumn>
    </GridRow>
  );
};
