import { urls } from 'services/_url';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import { CampaignPreview } from './sections/preview';
import { CampaignOverview } from './sections/overview';
import { ClicksLogs } from './sections/clicks';
import { BounceLogs } from './sections/bounces';
import { RetriesLog } from './sections/retries';
import { useCampaignService } from 'hooks/mailing/campaign';
import { useDispatch, useSelector } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { OpenLogs } from './sections/opens';
import ContextMenu from 'app/shared/datatable/context-menu/context-menu';
import { MoreButton } from 'app/shared/button/more';
import { campaignCanBeEdited } from 'utilities/form';
import styles from './view.module.css';
import { addOneCampaignToStore } from 'store/actions/campaign';
import { toast } from 'react-toastify';
import { Spacer } from 'app/layouts/generic';
import { SendsLogs } from './sections/sends';

const getNavItems = (campaign_id) => [
  {
    title: 'Overview',
    path: `/campaigns/${campaign_id}/`
  },
  {
    title: 'Details',
    path: `/campaigns/${campaign_id}/details`
  },
  {
    title: 'Delivery log',
    path: `/campaigns/${campaign_id}/deliveries`
  },
  {
    title: 'Opens log',
    path: `/campaigns/${campaign_id}/opens`
  },
  {
    title: 'Clicks log',
    path: `/campaigns/${campaign_id}/clicks`
  },
  {
    title: 'Bounce log',
    path: `/campaigns/${campaign_id}/bounces`
  },
  {
    title: 'Retries history',
    path: `/campaigns/${campaign_id}/retries`
  }
];

export const CampaignViewPage = () => {
  const { id } = useParams();
  const { token } = useSelector((state) => state.user_data);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fetchCampaign, resendCampaign } = useCampaignService();

  const [campaign, setCampaign] = useState({});
  useEffect(() => {
    fetchCampaign(id).then(({ campaign }) => {
      setCampaign(() => campaign);
      dispatch(
        setPageTitle([
          { title: 'My Campaigns', path: '/campaigns' },
          { title: campaign.name, path: `/campaigns/${id}` }
        ])
      );
    });
  }, [id]);

  const campaign_actions = [
    { label: 'Edit', value: 'edit' },
    { label: 'Resend to non-openers', value: 'resend' }
  ];

  const handleAction = (action) => {
    if (action === 'edit') {
      if (!campaignCanBeEdited(campaign)) return toast.error('Cannot edit campaign.');
      if (campaign.is_advanced) {
        window.location.href = `${urls.editor}/campaigns/${campaign.id}?t=${token}`;
        return;
      }

      navigate(`/editor/campaign/${campaign.id}`);
    }

    if (action === 'resend') handleRetryCampaign();
  };

  const handleRetryCampaign = async () => {
    const retry_count = campaign.metadata.retry_count ? campaign.metadata.retry_count + 1 : 1;
    const data = {
      ...campaign,
      metadata: {
        ...campaign.metadata,
        retry_count
      },
      schedule: {
        ...campaign.schedule,
        date: new Date()
      },
      status: 'queued',
      is_enqueued: false
    };

    resendCampaign(campaign, {
      data
    })
      .then((response) => {
        if (!response) return;
        dispatch(addOneCampaignToStore({ ...data }));
      })
      .catch(console.log);
  };

  return (
    <PageContentWrapper>
      <SlidingNav
        nav_items={getNavItems(id)}
        actions={
          <ContextMenu
            actions={campaign_actions}
            text={<MoreButton text="Actions" className={styles.actions} />}
            callback={handleAction}
          />
        }
      />
      <Spacer multiple={4} />
      <div className="dt-content-wrapper">
        <Routes>
          <Route path="bounces" element={<BounceLogs />} />
          <Route path="details" element={<CampaignPreview />} />
          <Route path="deliveries" element={<SendsLogs />} />
          <Route path="clicks" element={<ClicksLogs />} />
          <Route path="retries" element={<RetriesLog />} />
          <Route path="opens" element={<OpenLogs />} />
          <Route path="/*" element={<CampaignOverview />} />
        </Routes>
      </div>
    </PageContentWrapper>
  );
};
