import React, { useContext, useEffect, useState } from 'react';
import { DetailItem } from 'app/shared/info-section/item';
import { MultiSelect } from 'app/shared/select';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { useCurrencyService } from 'hooks/currency';
import { Spacer } from 'app/layouts/generic';
import styles from './purchase.module.css';
import { useTaxService } from 'hooks/sales/tax';
import { toReadableNumber } from '@go-mailer/jarvis/lib/utilitiy/number';
import { prepareCheckoutConfig } from 'app/member/modules/wallet/helper';
import { toast } from 'react-toastify';
import { usePaymentService } from 'hooks/sales/payment';
import { useTenantService } from 'hooks/iam/tenant';
import { useSelector } from 'react-redux';
import { PermissionsContext } from 'contexts/permissions';
import { useContactService } from 'hooks/users/contact';

export const ModulePurchase = ({ module_data = {} }) => {
  const period = [
    { label: 'Monthly', value: 'month' },
    { label: 'Yearly', value: 'year' }
  ];

  const { permissions } = useContext(PermissionsContext);
  const { fetchContacts } = useContactService();
  const { fetchCurrencies } = useCurrencyService();
  const { createPayment } = usePaymentService();
  const { fetchTenant } = useTenantService();
  const { fetchTaxes } = useTaxService();
  const { tenant_id } = useSelector((state) => state.user_data);

  const [currencies, setCurrencies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payment_details, setPaymentDetails] = useState({});
  const [prices, setPrices] = useState([]);
  const [renewal_period, setRenewalPeriod] = useState(period[0]);
  const [selected_currency, setSelectedCurrency] = useState({});
  const [selected_pricing, setSelectedPricing] = useState({});
  const [tax_rates, setTaxRates] = useState({});

  useEffect(() => {
    fetchTaxes().then(({ taxes }) => {
      setTaxRates(() =>
        taxes.reduce(
          (s, rate) => ({
            ...s,
            [rate.currency]: rate.tax
          }),
          {}
        )
      );
    });
  }, []);

  useEffect(() => {
    const sorted_pricing = (module_data.pricing || []).sort(
      (a, b) => a.contact_bracket.min - b.contact_bracket.min
    );

    fetchContacts({ query_string: 'count=1' }).then(({ size: count_count }) => {
      const valid_pricing = sorted_pricing.filter(
        (pricing) => Number(pricing.contact_bracket.max) >= Number(count_count)
      );

      const formatted_pricing = valid_pricing.map((pricing) => {
        const { min, max } = pricing.contact_bracket;
        return {
          ...pricing,
          value: min,
          label: `${Number(min).toLocaleString()} - ${Number(max).toLocaleString()}`
        };
      });

      setPrices(() => formatted_pricing);
      setSelectedPricing(formatted_pricing[0]);
    });

    fetchCurrencies().then(({ currencies }) => {
      const parsed_currencies = currencies.map((currency) => ({
        ...currency,
        value: currency.code,
        label: currency.code
      }));

      setCurrencies(() => parsed_currencies);
      setSelectedCurrency(parsed_currencies.find((curr) => curr.code === 'NGN') || {});
    });
  }, [module_data.code]);

  useEffect(() => {
    determinePaymentDetails();
  }, [selected_currency, selected_pricing, renewal_period]);

  const determinePaymentDetails = () => {
    if (!selected_currency?.value || isNaN(selected_pricing?.value) || !renewal_period?.value)
      return;

    const currency = selected_currency.value;
    const { discounts, prices } = selected_pricing;
    let cost = prices.find((p) => p.currency === currency)?.amount || 0;
    let discount_rate = discounts.month?.value;

    if (renewal_period.value === 'year') {
      cost *= 12;
      discount_rate = discounts.year?.value;
    }

    const tax_rate = tax_rates[currency];
    const discount = Number((cost * discount_rate) / 100);
    const discounted_price = Number(cost - discount);
    const tax = Number((discounted_price * Number(tax_rate)) / 100) || 0;

    setPaymentDetails(() => ({
      amount_to_pay: Number(discounted_price) + Number(tax),
      cost,
      discount,
      discount_rate,
      discounted_price,
      renewal_period: renewal_period.value,
      currency: selected_currency,
      tax,
      tax_rate
    }));
  };

  const handlePayment = async () => {
    const { tenant } = await fetchTenant(tenant_id);
    if (!tenant || !tenant.id) return toast.error('Unable to process tenant data');

    const { amount_to_pay, currency } = payment_details;
    const config = prepareCheckoutConfig(tenant, amount_to_pay, currency, 'card');
    const data = {
      currency: currency.value,
      conversion_rate: currency.exchange_rate,
      amount: amount_to_pay,
      tenant_id,
      tx_ref: config.tx_ref,
      reason: 'subscription',
      metadata: {
        module: { ...module_data, pricing: selected_pricing },
        payment_details
      }
    };

    setLoading(true);
    const payment_record = await createPayment({ data });
    if (payment_record) {
      window.FlutterwaveCheckout(config);
    }
    setLoading(false);
  };

  return (
    <>
      <GridRow num_of_columns={5}>
        <GridColumn span={3} />
        <GridColumn span={2}>
          <MultiSelect
            value={selected_currency}
            options={currencies}
            onChange={setSelectedCurrency}
          />
        </GridColumn>
      </GridRow>
      <Spacer multiple={4} />
      <GridRow num_of_columns={1}>
        <GridColumn>
          <div className={styles.summary}>
            <div>
              <span>
                <b>Cost: </b>
              </span>
              <span>
                <b>{toReadableNumber(payment_details.cost)}</b>
              </span>
            </div>
            <div className={styles.lineItem}>
              <span>Discount ({payment_details.discount_rate || 0}%): </span>
              <span>-{toReadableNumber(payment_details.discount)}</span>
            </div>
            <Spacer multiple={4} />
            <div className={styles.lineItem}>
              <span>
                <b>Subtotal: </b>
              </span>
              <span>
                <b>{toReadableNumber(payment_details.discounted_price)}</b>
              </span>
            </div>
            <div className={styles.lineItem}>
              <span>Tax ({payment_details.tax_rate || 0}%): </span>
              <span>{toReadableNumber(payment_details.tax)}</span>
            </div>
            <Spacer multiple={4} />
            <div>
              <span>
                <b>Total: </b>
              </span>
              <span>
                <b>{toReadableNumber(payment_details.amount_to_pay)}</b>
              </span>
            </div>
          </div>
        </GridColumn>
      </GridRow>
      <Spacer multiple={4} />
      <GridRow num_of_columns={3}>
        <GridColumn span={2}>
          <DetailItem title="Contact Bracket">
            <MultiSelect
              options={prices}
              onChange={setSelectedPricing}
              isSorted={false}
              value={selected_pricing}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Renewal period">
            <MultiSelect
              options={period}
              onChange={setRenewalPeriod}
              isSorted={false}
              value={renewal_period}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <Spacer multiple={4} />
      <GridRow num_of_columns={2}>
        <GridColumn />
        <GridColumn>
          <Button
            text="Pay"
            disabled={
              !permissions['integration:create'] ||
              !selected_currency?.value ||
              isNaN(selected_pricing?.value) ||
              !renewal_period?.value
            }
            loading={loading}
            onClick={handlePayment}
          />
        </GridColumn>
      </GridRow>
    </>
  );
};
