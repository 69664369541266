// import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  addAutomationSearchResultsToStore,
  addManyAutomationsToStore
  // updateAutomation
} from 'store/actions/automation';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { setPageTitle } from 'store/actions/header';
import { Button } from 'app/shared/button';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import styles from '../automation.module.css';
import { toast } from 'react-toastify';
import { useAutomationTemplateService } from 'hooks/automation/template';
import { truncateText } from 'app/shared/utils/general';
import { AutomationStatBar } from './stats';
import { Spacer } from 'app/layouts/generic';
import { PermissionsContext } from 'contexts/permissions';

export const WorkflowModule = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { fetchTemplates, searchTemplates, updateTemplates } = useAutomationTemplateService();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const automations_in_store = useSelector((state) => state.automation);
  const [automations, setAutomations] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total_automations, setTotalAutomations] = useState(0);
  const { permissions } = useContext(PermissionsContext);

  //
  const [action_to_take, setActionToTake] = useState('');
  const [items_to_act_on, setItemsToActOn] = useState([]);
  const [show_confirmation_dialog, setShowConfirmationDialog] = useState(false);

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Automations', path: '/automations/events' },
        { title: 'Workflows', path: '/automations/workflows' }
      ])
    );
  }, []);

  useEffect(() => {
    setAutomations(Object.values(automations_in_store));
  }, [automations_in_store]);

  const config = {
    actions: {
      single: (automation) => [
        { label: 'View/Edit', value: 'view', hidden: !permissions['automation:update'] },
        {
          label: 'Deactivate',
          value: 'deactivate',
          hidden: automation.status !== 'active' || !permissions['automation:update']
        },
        {
          label: 'Activate',
          value: 'activate',
          hidden: automation.status === 'active' || !permissions['automation:update']
        }
      ]
    },
    allow_bulk_action: false,
    css: {},
    fields: [
      {
        title: 'Title',
        key: 'title',
        isTitle: true,
        formatter: (value) => truncateText(value, 35)
      },
      {
        title: 'Description',
        key: 'description',
        isTagline: true
      },
      {
        title: 'Status',
        key: 'status',
        formatter: (value) => {
          switch (value) {
            case 'inactive':
              return <span className={styles.dangerBadge}>inactive</span>;
            case 'active':
              return <span className={styles.greenBadge}>active</span>;
          }
        },
        isBadge: true
      },
      {
        title: 'Date Created',
        key: 'created_on',
        formatter: (value) => {
          return value ? new Date(value).toDateString() : '';
        },
        isMetadata: true
      }
    ],
    is_search_mode,
    items: automations.sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'title',
    search_text: ''
  };

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoading(true);
      const { templates, size, error } = await fetchTemplates({
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });
      if (error) return toast.error(error);

      dispatch(addManyAutomationsToStore(templates));
      setTotalAutomations(size);
    } catch (e) {
      dispatch(addManyAutomationsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;
    if (type === 'single') {
      switch (name) {
        case 'view':
          history(`/editor/automations/${data.id}`);
          break;
        case 'deactivate':
          setActionToTake(name);
          setItemsToActOn([data]);
          setShowConfirmationDialog(() => true);
          break;
        case 'activate':
          setActionToTake(name);
          setItemsToActOn([data]);
          setShowConfirmationDialog(() => true);
          break;
        default:
      }
    }
  };

  const handleStatusConfirmation = async (is_permitted) => {
    if (is_permitted) {
      const status = action_to_take === 'activate' ? 'active' : 'inactive';
      const ids = items_to_act_on.map((template) => template.id).toString();
      const result = await updateTemplates({
        data: {
          options: { id: ids },
          data: {
            status
          }
        }
      });

      if (!result) {
        toast.error(`Could not ${action_to_take} automation at this time.`);
        return;
      }

      const updated_automations = items_to_act_on.map((template) => ({
        ...template,
        status
      }));

      dispatch(addManyAutomationsToStore(updated_automations));
      toast.success(`Automation ${action_to_take}d successfully`);
    }

    setActionToTake('');
    setItemsToActOn([]);
    setShowConfirmationDialog(() => false);
  };

  const handleItemClick = (payload) => {
    const { id } = payload;
    history(`/editor/automations/${id}`);
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { templates, size } = await searchTemplates(keys, keyword, {
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });

      setTotalAutomations(size);
      if (page === 0) return dispatch(addAutomationSearchResultsToStore(templates));
      dispatch(addManyAutomationsToStore(templates));
    } catch (e) {
      dispatch(addManyAutomationsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const table_actions = (
    <>
      <Button
        icon_name="add"
        text="Automation"
        onClick={() => history('/editor/automations/new')}
        disabled={!permissions['automation:create']}
      />
    </>
  );

  return (
    <>
      <AutomationStatBar />
      <Spacer multiple={2} />
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: total_automations
          }}
          checkbox
          loading_data={loading}
          table_actions={table_actions}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      )}
      <ConfirmationDialog
        message={`Are you sure you want to ${action_to_take} this template?`}
        callback={handleStatusConfirmation}
        is_open={show_confirmation_dialog}
      />
    </>
  );
};
