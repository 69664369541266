import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { useLinksService } from 'hooks/link';
import { useMailActionService } from 'hooks/mailaction';
import { useContactService } from 'hooks/users/contact';
import { truncateText } from 'app/shared/utils/general';
import { formatDateForDisplay } from 'app/shared/utils/date';
import { toReadableNumber } from 'app/shared/utils/number';
import { toast } from 'react-toastify';

export const ClicksLogs = () => {
  const { id: campaign_id } = useParams();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const { fetchContacts, searchContact } = useContactService();
  const { fetchLinks } = useLinksService();
  const { fetchMailActions } = useMailActionService();

  const [embedded_links, setEmbeddedLinks] = useState({});
  const [link_clickers, setLinkClickers] = useState({});
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total_clicks, setTotalClicks] = useState(0);

  const config = {
    actions: { single: () => [] },
    css: {},
    fields: [
      {
        title: 'First name',
        key: 'recipient',
        formatter: (value) => (value ? value.firstname : 'N/A'),
        isTitle: true
      },
      {
        title: 'Last name',
        key: 'recipient',
        formatter: (value) => (value ? value.lastname : 'N/A'),
        isTitle: true
      },
      {
        title: 'Email address',
        key: 'recipient_email',
        isTagline: true,
        formatter: (value) => truncateText(value, 25) || 'N/A'
      },
      {
        title: 'Gender',
        key: 'recipient',
        formatter: (value) => (value ? value.gender : 'N/A')
      },
      {
        title: 'Time clicked',
        key: 'time_stamp',
        isMetadata: true,
        formatter: (value) => formatDateForDisplay(value)
      },
      {
        title: 'Link',
        key: 'link',
        formatter: (value) => truncateText(value.link, 35) || 'N/A'
      },
      {
        title: 'Click count',
        key: 'occurences',
        formatter: (value) => toReadableNumber(value.length) || 'N/A'
      }
    ],
    is_search_mode,
    items: Object.values(link_clickers).sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'name',
    search_text: ''
  };

  const handleDataRequest = async (page, population = 50) => {
    if (loading) return;
    setLoading(true);

    const {
      mailactions,
      size,
      error: mailaction_error
    } = await fetchMailActions({
      query_string: `resource_type=campaign&action=clicked_link&resource_id=${campaign_id}&page=${page}&population=${population}`
    });
    if (mailaction_error) return toast.error(mailaction_error);
    const contact_ids = mailactions
      .map((action) => action.recipient_id)
      .filter((c_id) => !c_id.toString().includes('@'));

    const link_ids = mailactions.map((action) => (action.metadata ? action.metadata.link_id : ''));
    const [{ contacts, contact_error }, { links, error: link_error }] = await Promise.all([
      fetchContacts({ query_string: `id=${contact_ids.join()}` }),
      fetchLinks({ query_string: `id=${link_ids.join()}` })
    ]);

    if (contact_error || link_error) {
      toast.error('Unable to fetch links logs at this time.');
      return;
    }

    const contacts_by_id = contacts.reduce((s, c) => ({ ...s, [c.id]: c }), {});
    const links_by_id = links.reduce((s, l) => ({ ...s, [l.id]: l }), {});

    const actions = mailactions.map((action) => ({
      ...action,
      recipient: contacts_by_id[action.recipient_id],
      link: action.metadata ? links_by_id[action.metadata.link_id] : {}
    }));

    setEmbeddedLinks(() => links_by_id);
    setLinkClickers((curr_clickers) => ({
      ...curr_clickers,
      ...actions.reduce((s, a) => ({ ...s, [a.id]: a }), {})
    }));
    setTotalClicks(() => size);

    setLoading(false);
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys || !keyword) {
      setSearchMode(false);
      await handleDataRequest(page, population);
      return;
    }
    setLoading(true);
    try {
      const { contacts } = await searchContact(keys, keyword, {
        query_string: `page=0&population=${500}&return_only=id,firstname, lastname, email,gender`
      });

      if (contacts.length) {
        const contacts_by_id = contacts.reduce((s, c) => ({ ...s, [c.id]: c }), {});
        const contact_ids = contacts.map((c) => c.id);
        const { mailactions, size, error } = await fetchMailActions({
          query_string: `resource_type=campaign&action=clicked_link&resource_id=${campaign_id}&recipient_id=${contact_ids.join()}&page=${page}&population=${population}`
        });
        if (error) return toast.error(error);

        const actions = mailactions.map((action) => ({
          ...action,
          recipient: contacts_by_id[action.recipient_id],
          link: action.metadata ? embedded_links[action.metadata.link_id] : {}
        }));

        if (is_search_mode) {
          setLinkClickers((curr_clickers) => ({
            ...curr_clickers,
            ...actions.reduce((s, a) => ({ ...s, [a.id]: a }), {})
          }));
        } else {
          setLinkClickers(() => ({
            ...actions.reduce((s, a) => ({ ...s, [a.id]: a }), {})
          }));
          setSearchMode(true);
        }
        setTotalClicks(() => size);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <GridRow>
      <GridColumn span={4}>
        {is_mobile_view ? (
          <MobileDatatable
            config={config}
            action={() => {}}
            onClick={() => {}}
            onListModeChange={setSearchMode}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
            showHeader
          />
        ) : (
          <WebDatatable
            config={{
              ...config,
              total_count: total_clicks
            }}
            action={() => {}}
            onClick={() => {}}
            checkbox
            loading_data={loading}
            table_actions={[]}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
          />
        )}
      </GridColumn>
    </GridRow>
  );
};
