import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { setPageTitle } from 'store/actions/header';
import {
  addManyEventToStore,
  addEventSearchResultsToStore,
  loadEventsToStore
} from 'store/actions/event';
import styles from '../event.module.css';
import cx from 'classnames';
import { useEventsService } from 'hooks/automation/event';
import { useNavigate } from 'react-router-dom';
import { useSubscriptionService } from 'hooks/sales/subscription';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';

const NoSubscription = () => {
  const navigateTo = useNavigate();
  return (
    <div className={styles.noSubWrapper}>
      <GridRow num_of_columns={5}>
        <GridColumn span={2} />
        <GridColumn>
          <Button text="Activate Insights" onClick={() => navigateTo('/subscriptions')} />
        </GridColumn>
        <GridColumn span={2} />
      </GridRow>
    </div>
  );
};

export const AutomationEvents = () => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const event_in_store = useSelector((state) => state.events);

  const { fetchEvents, searchEvents } = useEventsService();
  const { fetchSubscription } = useSubscriptionService();

  const [events, setEvents] = useState([]);
  const [has_insights_subscription, setHasInsightsSubscription] = useState(false);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'App Insights', path: '/nsights/app' }]));
    fetchSubscription({ query_string: `plan_code=app_insights&status=active` }).then(
      ({ subscriptions: [subscription] }) => {
        setHasInsightsSubscription(subscription && subscription.id);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEvents(Object.values(event_in_store));
  }, [event_in_store]);

  const config = {
    actions: {
      single: () => []
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Name',
        key: 'name',
        searchable: true,
        isTitle: true
      },
      {
        title: 'Code',
        searchable: true,
        key: 'code',
        isTagline: true
      },
      {
        title: 'Type',
        key: 'type',
        formatter: (value) => {
          switch (value) {
            case 'system':
              return <span className={styles.blueBadge}>System</span>;
            default:
              return <span className={styles.infoBadge}>Custom</span>;
          }
        },
        isBadge: true
      }
    ],
    is_search_mode,
    items: events.sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'code,name',
    search_text: ''
  };

  const parseEvents = (data) => {
    const parsed_events = data?.map((event) => {
      const [code] = event.code.split(':');
      const new_element = {
        ...event,
        code
      };
      return new_element;
    });
    return parsed_events;
  };

  const handleDataRequest = async (page = 0, population = 50) => {
    try {
      setLoading(true);
      const { events } = await fetchEvents({
        query_string: `page=${page}&type=user&population=${population}&sort_by=-created_on`
      });
      const parsed_events = parseEvents(events);
      if (page === 0) dispatch(loadEventsToStore(parsed_events));
      else dispatch(addManyEventToStore(parsed_events));
    } catch (e) {
      dispatch(addManyEventToStore([]));
    }
  };

  const handleSearchRequest = async (keys, keyword, page = 0, population = 50) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { events } = await searchEvents(keys, keyword, {
        query_string: `type=user&sort_by=-created_on&page=${page}&population=${population}`
      });

      const parsed_events = parseEvents(events);
      if (page === 0) return dispatch(addEventSearchResultsToStore(parsed_events));
      dispatch(addManyEventToStore(parsed_events));
    } catch (e) {
      dispatch(addManyEventToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleItemClick = (data) => {
    if (!has_insights_subscription) return;
    navigateTo(`/insights/app/${data.code}`);
  };

  if (!has_insights_subscription) return <NoSubscription />;

  return (
    <div className={cx(styles.wrapper, 'dt-content-wrapper')}>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={() => {}}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
          showHeader={false}
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: config.items?.length
          }}
          action={() => {}}
          onClick={handleItemClick}
          checkbox
          request_complete={!loading}
          onDataRequest={handleDataRequest}
          loadingSearch={() => {}}
          onSearchRequest={handleSearchRequest}
        />
      )}
    </div>
  );
};
