import React from 'react';
import cx from 'classnames';
import styles from './info-section.module.css';

export const BasicInfoSection = ({ title, icon = null, bodyClassName, className, children }) => {
  return (
    <div className={cx(styles.detailsWrapper, className)}>
      {title && (
        <header>
          <span>{title}</span>
          {icon}
        </header>
      )}
      <div className={cx(styles.statSummary, bodyClassName)}>{children}</div>
    </div>
  );
};
