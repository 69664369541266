import { GridColumn, GridRow } from 'app/layouts/grid';
import { DetailItem } from 'app/shared/info-section/item';
import { MultiSelect } from 'app/shared/select';
import { useResourceQuery } from 'hooks/automation/resources';
import React, { useEffect, useState } from 'react';

const MAIL_TYPES = [
  { value: 'campaign', label: 'Campaign' },
  { value: 'transactional', label: 'Transactional' }
];

export const LinkTriggerConfig = ({ onSelection = () => {} }) => {
  const { fetchResources } = useResourceQuery();

  const [resources, setResources] = useState([]);
  const [selected_mail_type, setSelectedMailType] = useState(MAIL_TYPES[0]);
  const [selected_mail, setSelectedMail] = useState({});

  useEffect(() => {
    setSelectedMail(() => ({}));

    const bool_query = selected_mail_type.value === 'campaign' ? '&bool=-is_test_campaign' : '';
    fetchResources(selected_mail_type.value, '', {
      query_string: `return_only=name,id${bool_query}`
    }).then(({ resources: raw_resources, error }) => {
      if (error) return;

      const parsed_resources = raw_resources.map((res) => ({
        ...res,
        label: res.name || res.title || res.link,
        value: res.id
      }));

      setResources(() => parsed_resources);
    });
  }, [selected_mail_type.value]);

  useEffect(() => {
    if (!selected_mail.value) return;
    onSelection({
      target: `${selected_mail_type.value}:${selected_mail.value}`
    });
  }, [selected_mail.value]);

  return (
    <>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Email type">
            <MultiSelect
              options={MAIL_TYPES}
              onChange={setSelectedMailType}
              value={selected_mail_type}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title={`Select ${selected_mail_type.label}`}>
            <MultiSelect options={resources} onChange={setSelectedMail} value={selected_mail} />
          </DetailItem>
        </GridColumn>
      </GridRow>
    </>
  );
};
