import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { useContactService } from 'hooks/users/contact';
import { formatDateForDisplay } from 'app/shared/utils/date';
import { useAudienceService } from 'hooks/users/audience';
import { toast } from 'react-toastify';
import {
  addAudContactsSearchResults,
  addManyAudContacts,
  clearAudContacts,
  removeAudContacts
} from 'store/actions/audience-contact';
import { truncateText } from 'app/shared/utils/general';

export const AudienceContacts = ({ is_valid = true }) => {
  const { id: audience_id } = useParams();
  const dispatch = useDispatch();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const contacts_in_store = useSelector((state) => state.audience_contacts);
  const { removeContacts } = useAudienceService();
  const { fetchAudienceContacts } = useContactService();

  const [clear_table, setClearTable] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total_contacts, setTotalContacts] = useState(0);

  const config = {
    actions: {
      bulk: [{ label: 'Remove', value: 'remove' }],
      single: () => [{ label: 'Remove', value: 'remove' }]
    },
    css: {},
    fields: [
      {
        title: 'First name',
        key: 'firstname',
        formatter: (value) => value || 'N/A',
        isTitle: true
      },
      {
        title: 'Last name',
        key: 'lastname',
        isTitle: true,
        formatter: (value) => value || 'N/A'
      },
      {
        title: 'Email address',
        key: 'email',
        isTagline: true,
        formatter: (value) => truncateText(value, 35) || 'N/A'
      },
      {
        title: 'Date added',
        key: 'created_on',
        formatter: (value) => formatDateForDisplay(value),
        isMetadata: true
      }
    ],
    clear_table,
    is_search_mode,
    items: contacts.sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'name',
    search_text: ''
  };

  useEffect(() => {
    setClearTable(() => true);
    dispatch(clearAudContacts({}));
  }, [is_valid]);

  useEffect(() => {
    setContacts(() => Object.values(contacts_in_store));
  }, [contacts_in_store]);

  const handleDatatableAction = async (action) => {
    const { name, type, data } = action;

    if (type.toLowerCase() === 'bulk') {
      switch (name.toLowerCase()) {
        case 'remove':
          await handleDeletion(data.map((contact) => contact.id));
          break;
        default:
      }
    }

    if (type.toLowerCase() === 'single') {
      switch (name.toLowerCase()) {
        case 'remove':
          handleDeletion([data.id]);
          break;
        default:
      }
    }
  };

  const handleDataRequest = async (page, population = 50) => {
    if (loading) return;
    setLoading(true);
    const validity = is_valid ? 'is_valid' : '-is_valid';
    const { contacts: c_response, size } = await fetchAudienceContacts(audience_id, {
      query_string: `bool=${validity}&page=${page}&population=${population}`
    });

    setClearTable(() => false);
    dispatch(addManyAudContacts(c_response));
    setTotalContacts(() => size);

    setLoading(false);
  };

  const handleDeletion = async (contact_ids = []) => {
    const result = await removeContacts(audience_id, contact_ids);
    if (!result) return;

    toast.success('Contact removed successfully');
    dispatch(removeAudContacts(contact_ids));
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys || !keyword) {
      setSearchMode(false);
      await handleDataRequest(page, population);
      return;
    }
    setLoading(true);
    const validity = is_valid ? 'is_valid' : '-is_valid';
    const { contacts: c_response, size } = await fetchAudienceContacts(audience_id, {
      query_string: `bool=${validity}&page=${page}&population=${population}&search_key=${keys}&search_keyword=${keyword}`
    });

    setClearTable(() => false);
    if (is_search_mode) {
      dispatch(addAudContactsSearchResults(c_response));
    } else {
      dispatch(addManyAudContacts(c_response));
      setSearchMode(true);
    }

    setTotalContacts(() => size);
    setLoading(false);
  };

  return (
    <div className="contactPage">
      <GridRow>
        <GridColumn span={4}>
          {is_mobile_view ? (
            <MobileDatatable
              config={config}
              action={() => {}}
              onClick={() => {}}
              onListModeChange={setSearchMode}
              onDataRequest={handleDataRequest}
              onSearchRequest={handleSearchRequest}
              showHeader
            />
          ) : (
            <WebDatatable
              config={{
                ...config,
                total_count: total_contacts
              }}
              action={handleDatatableAction}
              onClick={() => {}}
              checkbox
              loading_data={loading}
              table_actions={[]}
              onDataRequest={handleDataRequest}
              onSearchRequest={handleSearchRequest}
            />
          )}
        </GridColumn>
      </GridRow>
    </div>
  );
};
